@import '~font-awesome/css/font-awesome.min.css';
@import '~react-big-calendar/lib/css/react-big-calendar.css';

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

#root .container {
  height: 100%;
}

/* Maximise total width. Normally 65% */
.redux-toastr .toastr .rrt-middle-container {
  width: 70%;
}

/* Center toastr content vertically */
.toastr {
  display: flex;
  align-items: center;
}

/* Center toast icons horizontally and vertically */
.toastr .rrt-left-container .rrt-holder {
  display: flex;
  align-items: center;
  justify-content: center;
}


.rbc-btn-group button, .rbc-event {
  border-radius: 0;
}

.ant-calendar-header a.ant-calendar-prev-month-btn {
  margin-left: 5px;
}

.ant-calendar-header a.ant-calendar-next-month-btn {
  margin-right: 5px;
}

/**
    FIXME: Turns out we might not be using emotion.js as intended.
    !important might be avoidable if loading in _after_ css in our sub-modules.
**/
.ant-select-selection__choice {
  border-radius: 0 !important;
}

.ant-tooltip-inner {
  word-break: normal;
}

.reverse-column-right > .ant-table-column-sorters {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-right: 0 !important;
}
.reverse-column-right .ant-table-column-sorter {
  margin: 0 0.5em 0.25em 0;
}

@font-face {
  font-family: VyDisplay;
  src: url('../../fonts/VyDisplay-Medium.woff2');
  font-weight: 700;
}

@font-face {
  font-family: VySans-Bold;
  src: url('../../fonts/VySans-Bold.woff2');
  font-weight: 500;
}

@font-face {
  font-family: VySans-BoldItalic;
  src: url('../../fonts/VySans-BoldItalic.woff2');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: VySans-Light;
  src: url('../../fonts/VySans-Light.woff2');
  font-weight: 300;
}

@font-face {
  font-family: VySans-LightItalic;
  src: url('../../fonts/VySans-LightItalic.woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: VySans-Regular;
  src: url('../../fonts/VySans-Regular.woff2');
  font-weight: 400;
}

@font-face {
  font-family: VySans-RegularItalic;
  src: url('../../fonts/VySans-RegularItalic.woff2');
  font-weight: 400;
  font-style: italic;
}

h1, h2, h3, h4, h5, h6 {
  font-family: VyDisplay, sans-serif;
}

a {
  font-family: VySans-Regular, sans-serif;
}

label {
  font-family: VySans-Bold, sans-serif
}

body, div, span {
  font-family: VySans-Regular, sans-serif;
}

p {
  font-family: VySans-Regular, sans-serif;
}

button, button span {
  font-family: VySans-Light, sans-serif;
}

table {
  font-family: VySans-Regular, sans-serif;
}

.ant-table-tbody {
  font-family: VySans-Light, sans-serif;
}

.ant-table-tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}

.event-popover > .ant-popover-content > .ant-popover-inner > div > .ant-popover-title {
  padding: 0;
}

.Toastify__toast {
  font-family: VySans-Regular;
}

.Toastify__toast--default {
  background: #fff;
  color: #A6A9A6;
}
.Toastify__toast--info {
  background: #99C3BF;
  color: #383E42;
}
.Toastify__toast--success {
  background: #00957A;
}
.Toastify__toast--warning {
  background: #FAE053;
}
.Toastify__toast--error {
  background: #FF8150;
}
